<template>
    <defaultSec :title="'我的信息'">
        <el-descriptions :column="1" border label-class-name="my-label">
            <el-descriptions-item label="用户名">{{userNick}}</el-descriptions-item>
            <el-descriptions-item label="账号">{{userName}}</el-descriptions-item>
            <el-descriptions-item label="修改密码">
                <el-button type="danger" size="small" @click="editPassword">修改密码</el-button>
            </el-descriptions-item>
        </el-descriptions>
        <editPassword ref="editPasswordDialog"></editPassword>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import editPassword from "@c/editPassword";

    export default {
        data() {
            return {
                userName:'',
                userNick:'',
                form:{
                    password1:'',
                    password2:''
                }
            }
        },
        components:{
            defaultSec,editPassword
        },
        mounted(){
            this.userNick = this.getCookie('userNick')
            this.userName = this.getCookie('userName')
        },
        methods: {
            getCookie(name){
                var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
                if (arr = document.cookie.match(reg))
                    return unescape(arr[2]);
                else
                    return null;
            },
            editPassword(){
                this.$refs.editPasswordDialog.openEditDialog()

            }
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">
   .my-label{
       width: 100px;
       color: #ffffff!important;
       background: #209e91!important;
   }

</style>